import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import "./global.css";
import LocationComponet from "./locationPageParkHeights";
import FixedFooter from "./Footerfixed";
import Container from "./Container";
import { css } from "@emotion/react";
import LogofromImagesFolder from "../images/icon.png";
import Title from "./TitleComponent";
import Sidemenu from "./SideMenu";
import SEO from "./SEO";

const Layout = (props) => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);
  return (
    <>
      <SEO title={props.title} />
      <Navbar />
      <Sidemenu />
      <>{props.children}</>
      <LocationComponet />
      <Container>
        <div css={main}>
          <img
            src={LogofromImagesFolder}
            alt="sumadhra"
            style={{ width: "190px", height: "80px" }}
          />
          <div>
            <p>
              We operate with a belief that everyone deserves a better life.
              Over the past twenty years Adarsh has delivered over forty comes
              where thousands of happy customers reside. Our comes sq. live
              designed to provide exceptional life experiences, security and
              price. Adarsh properties have seen the absolute best rate of
              capital appreciation Associate in Nursingd have given our
              customers Associate in Nursing quality to possess for all times.
              With innovation, quality, and property at the core of our work and
              a unique consumer central approach has place us on the league of
              the simplest and most certain developers at intervals the
              business. Our expertise in land acquisition, understanding the
              consumer desires, execution capabilities along side the
              appointment of architects, designers, project managers along with
              exceptional sales and once sales service has helped us to be
              counted among the forerunners in assets development.
            </p>
          </div>
        </div>
      </Container>
      <div css={before}>
        <b>Disclaimer</b> : The content is for information purposes only and
        does not constitute an offer to avail of any service. Prices mentioned
        are subject to change without notice and properties mentioned are
        subject to availability. Images for representation purposes only. This
        is the official website of authorized marketing partner. We may share
        data with RERA registered brokers/companies for further processing. We
        may also send updates to the mobile number/email id registered with us.
        All Rights Reserved.
      </div>

      <div css={lastBefore}>
        <p>
          © Official Channel Patner. All Rights Reserved | Rera :
          PRM/KA/RERA/1251/310/AG/190510/001384
        </p>
      </div>
      <div css={offset > 250 ? one : two}>
        <FixedFooter />
      </div>
    </>
  );
};

export default Layout;

const main = css`
  text-align: center;
  p {
    text-align: justify;
    padding: 10px 20px;
    color: #000;
    letter-spacing: 0.02vw;
    line-height: 1.5;
    font-weight: 450;
    font-size: 16px;
    @media (max-width: 600px) {
      font-weight: 400;
      padding: 10px;
    }
  }
`;

const before = css`
  font-size: 11px;
  padding: 10px 40px;
  @media (max-width: 600px) {
    padding: 10px;
    text-align: center;
  }
`;

const lastBefore = css`
  background-color: #050505;
  padding: 10px 0 10px;
  font-size: 15px;
  text-align: center;
  margin-bottom: 2.1rem;
  line-height: 24px;
  color: #f3f3f3;
  @media (max-width: 600px) {
    font-size: 11px;
    padding: 1px;
  }
`;

const one = css`
  display: block;
`;
const two = css`
  display: none;
`;
